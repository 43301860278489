import React from "react";
import {
  Link,
  Tooltip,
  IconButton,
  Zoom,
  Box,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  socialIcons: {
    position: "absolute",
    bottom: theme.spacing(6),
    right: theme.spacing(6),
    "@media (max-width: 768px)": {
      bottom: theme.spacing(4),
      right: theme.spacing(4),
    },
  },
  iconButton: {
    height: "60px",
    display: "block",
    borderRadius: "18px",
  },
  icon: {
    fontSize: "32px",
  },
  text: {
    marginRight: "12px",
    fontFamily: "Vazirmatn",
  },
}));

export const FooterText = () => {
  const classes = useStyles();

  return (
    <div className={classes.socialIcons}>
      <Link
        href="mailto:admin@kamentgram.fun"
        rel="noopener noreferrer"
        underline="none"
        color="inherit"
      >
        <Box display="felx" alignItems="center">
          <IconButton
            color="inherit"
            aria-label="Instagram"
            className={classes.iconButton}
          >
            <i className={`${classes.icon} fas fa-envelope`}></i>
            <Typography variant="h6" component="h6" className={classes.text}>
              تماس با ما
            </Typography>
          </IconButton>
        </Box>
      </Link>
    </div>
  );
};
