import React from "react";
import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  svg: {
    width: "90px",
    height: "90px",
    position: "absolute",
    top: theme.spacing(6),
    right: theme.spacing(6),
    "@media (max-width: 768px)": {
      top: theme.spacing(4),
      right: theme.spacing(4),
    },
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    borderRadius: "18px",
  },
}));

export const LogoLink = () => {
  const classes = useStyles();

  return (
    <Link
      variant="h6"
      href="/"
      underline="none"
      color="inherit"
      noWrap
      className={classes.svg}
    >
      <img src="/main-logo.png" alt="logo" width={90} height={90} />
    </Link>
  );
};
