import React from "react";
import { Link, Tooltip, IconButton, Zoom } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  socialIcons: {
    position: "absolute",
    top: theme.spacing(6),
    left: theme.spacing(6),
  },
  iconButton: {
    height: "60px",
    width: "60px",
    display: "block",
    borderRadius: "18px",
  },
  icon: {
    fontSize: "32px",
  },
}));

export const SocialIcons = () => {
  const classes = useStyles();

  return (
    <div className={classes.socialIcons}>
      <Link
        href="https://instagram.com/kamentgram/"
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
        color="inherit"
      >
        <IconButton
          color="inherit"
          aria-label="Instagram"
          className={classes.iconButton}
        >
          <i className={`${classes.icon} fab fa-instagram`}></i>
        </IconButton>
      </Link>
    </div>
  );
};
