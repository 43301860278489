import React, { useContext } from "react";
import { ThemeContext } from "./ThemeProvider";
import { Tooltip, IconButton, Zoom } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Brightness4, Brightness7 } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    position: "absolute",
    bottom: theme.spacing(6),
    left: theme.spacing(6),
    "@media (max-width: 768px)": {
      bottom: theme.spacing(4),
      left: theme.spacing(4),
    },
    height: "60px",
    width: "60px",
    display: "block",
    borderRadius: "18px",
  },
  icon: {
    fontSize: "32px",
  },
}));

export const ThemeToggle = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const classes = useStyles();

  return (
    <IconButton
      color="inherit"
      onClick={toggleTheme}
      aria-label={"Toggle theme"}
      className={classes.iconButton}
    >
      {theme === "light" ? (
        <Brightness4 className={classes.icon} />
      ) : (
        <Brightness7 className={classes.icon} />
      )}
    </IconButton>
  );
};
