import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "../components/theme/ThemeProvider";
import { CssBaseline } from "@material-ui/core";

import { Home } from "../pages/Home";

export const App = () => {
  return (
    <ThemeProvider>
      <CssBaseline />
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="*" component={Home} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};
