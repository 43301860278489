import React from "react";
import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "./TextDecrypt";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: "20%",

    "@media (max-width: 768px)": {
      marginRight: "0px",
      paddingRight: theme.spacing(4),
    },
  },
  text: {
    fontFamily: "Vazirmatn",
  },
}));

export const Content = () => {
  const classes = useStyles();

  return (
    <Container component="main" className={`${classes.main}`} maxWidth="sm">
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        className={classes.text}
      >
        <TextDecrypt text={`خنده نا‌محدود  :)`} />
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        <TextDecrypt
          text={`جالب ترین گرافیک دیزاین های که توی اینترنت میتونین پیدا کنین، اینجاست`}
        />
      </Typography>
    </Container>
  );
};
